import { GetStaticPropsContext, NextPage } from 'next'
import { Text } from '@/src/common/components/elements/Text/Text'
import Image from 'next/legacy/image'
import getBuilderPromoBanners from '../src/common/queries/getBuilderPromoBanners'
import { styled } from '@/src/stitches.config'
import Link from 'next/link'
import { getBuilderBannersOrPromos } from '@/src/common/queries/getBuilderBannersOrPromos'

const PageWrapper = styled('div', {
    width: '$default',
    maxWidth: '$defaultMax',
    minHeight: 300,
    display: 'flex',
    fd: 'column',
    ai: 'center',
    margin: 'auto',
    gap: 40,
    jc: 'space-between',
    py: 30,
    '@media (min-width: 768px)': {
        fd: 'row',
        py: 40,
    },
    '.gradient': {
        background: `-webkit-linear-gradient(83.53deg, $primary 18.47%, $secondary 78%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
})

const TextWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    '& p': {
        lineHeight: '1.75rem',
        fontSize: '1.15rem',
        marginBottom: '20px',
    },
    '& h1': {
        fontWeight: 'bold',
        lineHeight: '3rem',
        fontSize: '2.45rem',
        marginBottom: '20px',
    },
    '& a': {
        lineHeight: '35px',
        color: '$primary',
        textDecoration: 'underline',
    },
    '@media (min-width: 768px)': {
        width: '50%',
        maxWidth: '425px',
    },
})

const BlueLine = styled('div', {
    borderBottom: '3px solid $secondary',
    maxWidth: '65px',
    marginBottom: '30px',
    height: '3px',
})

const ImageWrapper = styled('div', {
    width: '100%',
    height: 'auto',
    '@media (min-width: 768px)': {
        width: '50%',
        maxWidth: 450,
    },
})

const Custom404: NextPage = () => {
    return (
        <PageWrapper data-testid={'404'}>
            <TextWrapper>
                <BlueLine />
                <Text as={'h1'} data-testid={'h1Text404'}>
                    <span className={'gradient'}>Sorry,</span> we can&apos;t
                    find that page.
                </Text>
                <Text as={'p'}>
                    Looks like this URL no longer exists. Try navigating by
                    choosing one of the links below.
                </Text>
                <Link href={'/visa-gift-cards'} passHref>
                    Visa gift cards
                </Link>
                <Link href={'/brands'} passHref>
                    Brand eGift cards
                </Link>
                <Link href={'/corporate/bulk'} passHref>
                    Corporate &#38; bulk
                </Link>
                <Link href={'/activate'} passHref>
                    Activate a card
                </Link>
                <Link href={'/sitemap'} passHref>
                    Sitemap
                </Link>
                <Link href={'/faq'} passHref>
                    Frequently asked questions
                </Link>
            </TextWrapper>
            <ImageWrapper>
                <Image
                    src={'/images/404_illustration.png'}
                    layout={'intrinsic'}
                    height={658}
                    width={586}
                    alt={'404 page illustration'}
                    data-testid={'404Image'}
                />
            </ImageWrapper>
        </PageWrapper>
    )
}

export async function getStaticProps({
    params,
}: GetStaticPropsContext<{ page: string[] }>) {
    const { corporateBanner } = await getBuilderPromoBanners()
    const { consumerData: consumerBanner } = await getBuilderBannersOrPromos(
        true
    )

    return {
        props: {
            consumerBanner,
            corporateBanner,
            content: {
                data: {
                    title: 'Page Not Found',
                    description:
                        'We could not find the page you were looking for.',
                },
            },
        },
        // Next.js will attempt to re-generate the page:
        // - When a request comes in
        // - At most once every 5 seconds
        revalidate: true,
    }
}

export default Custom404
